<template>
    <router-view></router-view>
</template>

<script>

export default {
    name: 'App',
    components: {
        
    }
}
</script>

<style lang="less">
@import "@/assets/css/style.less";
</style>
